import React from 'react';
import { Helmet } from "react-helmet";
import TemplateWrapper from '../../components/layout';

const Dlina = () => (
  <TemplateWrapper>
	<div className="article-content">

		<Helmet>
			<title>Бесплатное наращивание волос</title>
			<meta name="description" content="Наращивание волос абсолютно бесплатно! Звучит как сказка? Участвуйте в конкурсе и проверьте сами!" />
			<meta name="keywords" content="нарастить волосы бесплатно, бесплатное наращивание в москве, нарастить волосы на модель" />
		</Helmet>

		<h1 className="hm-title-1">Конкурс: бесплатное наращивание волос!</h1>

		<p>Каждый месяц мы разыгрываем бесплатное наращивание волос, все что от тебя требуется - заполнить анкету и в случае выигрыша, сделать репост в социальных сетях с хэштегом <b>#hair.moscow</b>.
		Результаты конкурса публикуются каждый месяц первого числа на этой странице. Одна победительница выбирается абсолютно случайно.
		  Разыгрываются услуги по наращиванию волос (полная стоимость работы в нашем салоне). Участвуй и преображайся абсолютно бесплатно!
    	</p>

		<h2>Анкета для участия в конкурсе</h2>
		<form action="https://formspree.io/master@hair.moscow" method="post" className="contact-form-box" encType="multipart/form-data">
			<fieldset>
				<div className="clearfix">
					<div className="col-xs-12 col-md-9">
						<input type="hidden" name="_language" value="ru" />
						<div className="form-group">
							<label htmlFor="name">Ваше имя</label>
							<input type="text" className="form-control" id="name" name="name" />
						</div>
						<div className="form-group">
							<label htmlFor="phone">Ваш телефон или почта для связи</label>
							<input type="text" className="form-control" id="phone" name="phone" />
						</div>
					</div>
				</div>
				<div className="submit">
					<button type="submit" className="btn btn-default btn-md hm-button">
						<span>
							Принять участие в конкурсе!<i className="fa fa-chevron-right right"></i>
						</span>
					</button>
				</div>
				<br />
			</fieldset>
		</form>
	</div>
  </TemplateWrapper>
)

export default Dlina